import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Grid, Row, Col, up, css, Breakpoint, th } from '@smooth-ui/core-sc'
import { Helmet } from 'react-helmet'

import { MainLayout } from '../layouts/MainLayout'
import { Header } from '../components/Header/Header'
import { VerticalSpacer } from '../style/globalStyleComponents'
import { renderDate } from '../utils/utils'
import { TopImageBanner } from '../components/TopImageBanner'

export const query = graphql`
  {
    prodResults: allPrismicBlogpost(filter:{data:{ show_in_production: {eq:"Yes"}}
  }, sort: {
      fields: first_publication_date,
      order:DESC
    }) {
      edges {
        node {
          id
          uid
          last_publication_date
          data {
            title {
              text
            }
            content {
              text
            }
            author {
              text
            }  
            thumbnail {
              localFile {
                childImageSharp {
                  small: fixed(width: 56, height: 56) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  large: fixed(width: 160, height: 160) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    blogs: allPrismicBlogpost(sort: {
      fields: first_publication_date,
      order:DESC
    }) {
      edges {
        node {
          id
          uid
          last_publication_date
          data {
            title {
              text
            }
            content {
              text
            }
            author {
              text  
            }  
            thumbnail {
              localFile {
                childImageSharp {
                  small: fixed(width: 56, height: 56) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  large: fixed(width: 160, height: 160) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Blog = ({ data, location, ...rest }) => {
  const results = process.env.GATSBY_ACTIVE_ENV === 'production' ? data.prodResults : data.blogs
  const topImageBanner = TopImageBanner(true)

  return (
    <MainLayout pathname={location.pathname}>
      <Helmet title='Blog'
        meta={[
          { property: 'og:title', content: 'Blog | SportMe' },
          { property: 'og:url', content: `${location.href}` },
          { property: 'og:description', content: `SportMe will keep you up to date with the latest sports and running news.` }
        ]} />
      <Header pathname={location.pathname} />
      <Grid>
        { topImageBanner }
      </Grid>
      <Grid>
        <VerticalSpacer>
          <h1>SportMe Blog</h1>
          <h3>Stay up to date with the latest news in sports</h3>
        </VerticalSpacer>
      </Grid>

      <Grid pt="30px">
        {results.edges.map(({ node }, idx) => (
          <Row key={idx}>
            <Col>
              <BlogPost href={`/blog/${node.uid}`} target='_blank' to={`/blog/${node.uid}`}
                 height={node.data.author.text}
                 aria-label={`link to ${node.data.title.text}`}>
                <StyledH2>{node.data.title.text}</StyledH2>
                <DateAndAuthor>
                  <StyledDate>
                      {renderDate(node.last_publication_date)}
                  </StyledDate>
                  { node.data.author && node.data.author.text && <Author small>by {node.data.author.text}</Author> }
                </DateAndAuthor>
                <StyledBreakpoint down="sm">
                  <RoundedCornerImg
                    fixed={node.data.thumbnail.localFile.childImageSharp.small}
                  />
                </StyledBreakpoint>
                <StyledBreakpoint up="sm">
                  <RoundedCornerImg
                    fixed={node.data.thumbnail.localFile.childImageSharp.large}
                  />
                </StyledBreakpoint>
                <TextContainer>
                  <Paragraph>{node.data.content.text}</Paragraph>
                  <ContinueReading><span>...</span>continue reading</ContinueReading>
                </TextContainer>
              </BlogPost>
            </Col>
          </Row>
        ))}
      </Grid>
    </MainLayout>
  )
}

const StyledH2 = styled.h2`
  font-size: 24px;
  line-height: 30px;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${up(
    'sm',
    css`
      font-size: ${th('h2FontSize')};
      line-height: 41px;
    `
  )}
`

const StyledDate = styled.span`
  display: block;
  padding-bottom: 0;
  font-size: 14px;
  color: ${th('gray600')};

  ${up(
    'sm',
    css`
      font-size: 18px;
    `
  )}
`

const StyledBreakpoint = styled(Breakpoint)`
  position: relative;

  ${up(
    'sm',
    css`
      position: absolute;
      left: 0;
      top: 0;
    `
  )}
`

const RoundedCornerImg = styled(Img)`
  border-radius: 5px;
  margin-right: 10px;
  float: left;

  ${up('sm', css`
      border-radius: 20px;
      margin-right: 20px;
  `)}
`

const BlogPost = styled.a`
  display: block;
  position: relative;
  overflow: hidden;
  height: ${props => props.height ? '142px': '120px'};
  margin-bottom: 40px;

  ${up('sm', css`
      padding-left: 180px;
      height: 160px;
  `)}

  ${up('lg', css`
    &:hover {
      ${RoundedCornerImg} {
        &:before {
          content: ' ';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: rgba(240, 100, 73, 0.8);
          z-index: 2;
        }
      }
    }
  `)}
`

const TextContainer = styled.div`
  position: relative;
  top: -3px;
  
  ${up('sm', css`
    top: 0;
  `)}
`

const Paragraph = styled.p`
  margin: 0;
  color: ${th('gray600')};
  line-height: 21px;
  :after {
        background: linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,1) 17%);
        bottom: 0;
        content: "";
        height: 1.2em;
        left: 65px;
        position: absolute;
        right: 0;
        text-align: right;
        top: 43px;
        width: 100%;
  }
  
  ${up('sm', css`
      height: 66px;
      overflow: hidden;
      :after {
          left: auto;
          top: auto; 
          width: 100%; 
      }
  `)}
`

const DateAndAuthor = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  
  ${up('sm', css`
    padding-bottom: 5px;
  `)}
  
  ${up('md', css`
    flex-direction: row;
    padding-bottom: 15px;
  `)}
`

const Author = styled.span`
  font-size: 14px;
  color: rgba(26,26,26,0.8);

  ${up('sm', css`
    font-size: 18px;
  `)}
  
  ${up('md', css`
    margin-left: 10px;
  `)}
`

export const ContinueReading = styled.div`
  position: absolute;
  left: 132px;
  top: 39px;
  width: 100%;
  z-index: 2;
  span {
    color: rgba(26,26,26,0.6);
    padding: 0 3px 0 5px
  }

  ${up('sm', css`
      left: 60px;
  `)}

   ${up('md', css`
      left: 85px;
   `)}
   
  ${up('lg', css`
      left: 130px;
  `)}

   ${up('xl', css`
      left: 175px;
   `)}
`;

export default Blog
